body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: "LoveFrom, Serif", Georgia, serif;
  background-color: #f5f5f5;
  height: 100%;
}

.Header {
  font-size: 2rem;
  font-weight: 100;
  font-style: normal;
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

.quote {
  display: flex;
  justify-content: space-between;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  align-items: center;
  padding: 1.5rem 5% 1.5rem 5%;
}

.video-modal {
  background: black;
  height: 100vh;
}

iframe {
  -webkit-animation: slide-up 1s forwards;
          animation: slide-up 1s forwards;
  width: 1000px;
  height: 600px;
}

.quote-text {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  font-size: x-large;
  align-self: center;
  text-align: left;
  line-height: 1.2;
}

.quote-text:hover {
  cursor: pointer;
}

.expand-button {
  width: 20%;
  height: 80%;
  margin: 5px;
}

a {
  text-decoration: none;
}

a, a:visited, a:hover {
  color: inherit;
}

@-webkit-keyframes slide-up {
  0% {
    margin-top: 100%;
  }

  100% {
    margin-top: 50px;
  }
}

@keyframes slide-up {
  0% {
    margin-top: 100%;
  }

  100% {
    margin-top: 50px;
  }
}

@media (max-width: 400px) {
  .Header {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
  }

  .header-text {
    margin-bottom: 10px;
  }

  iframe {
    width: 390px;
    height: 219px;
  }

  @-webkit-keyframes slide-up {
    0% {
      margin-top: 100%;
    }
  
    100% {
      margin-top: 50%;
    }
  }

  @keyframes slide-up {
    0% {
      margin-top: 100%;
    }
  
    100% {
      margin-top: 50%;
    }
  }
}
